import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadReport } from "context/ApiService";
import { RefreshRounded } from "@mui/icons-material";
import { startReportGeneration } from "context/ApiService";

const DownloadReport = (props) => {
    const [isOpne, setOpen] = useState(false);
    const { batch } = props;
    const { report } = batch;

    useEffect(() => {
        console.log(report?.processStatus, "reportreportreportreport");
    });

    const handleModalOpen = () => {
        setOpen(true);
    }

    const downloadReportByUid = async () => {
        
        try{
            let response = await downloadReport(report?.uid);

            var fileName = batch.name;
            var todayDate = new Date().toISOString().slice(0, 10);

            let FileName = `${fileName}-${todayDate}.xlsx`;

            const blob = response.data;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = FileName;
            link.click();

            link.remove();

        }catch(error){

        }
    }

    const startReportGenerationByReportUid = async () => {
        
        try{
            let response = await startReportGeneration(batch?.uid);

            handleModalClose();

        }catch(error){

        }
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }
    
    const UpdateBrandModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle sx={{textAlign: 'center'}}>Download Report</DialogTitle>
                <DialogContent sx={{maxWidth: '300px'}}>
                    
                    {report?.processStatus == "GENERATING" && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Report is being generated for batch <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography>
                            . Please wait for a while. You'll receive an email when generated.
                        </SoftTypography>
                    </>}
                    
                    {(report?.processStatus != "GENERATED" && report?.processStatus != "GENERATING") && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Generate report for <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography>. Generating Record may take some time and the status will be mailed as soon as the record is ready to download.
                        </SoftTypography>
                    </>}

                    {report?.processStatus == "GENERATED" && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Report for  <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography> is ready to download. Click on download to start.
                        </SoftTypography>
                    </>}
                    <SoftBox mt={4} mb={1}>
                        {(report?.processStatus != "GENERATED" && report?.processStatus != "GENERATING") && <SoftButton type="submit" variant="gradient" color="error" fullWidth onClick={() => { startReportGenerationByReportUid() }}>
                            <FileDownloadIcon />&nbsp;Generate Report
                        </SoftButton>}
                        {report?.processStatus == "GENERATING" && <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                            <RefreshRounded />&nbsp;Generating...
                        </SoftButton>}
                        {report?.processStatus == "GENERATED" && <SoftButton type="submit" variant="gradient" color="success" fullWidth onClick={() => {downloadReportByUid()}}>
                            <FileDownloadIcon />&nbsp;Download Report
                        </SoftButton>}
                        <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                            Close
                        </SoftButton>
                    </SoftBox>
                    
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <SoftButton type="button" variant="text" color="error" onClick={handleModalOpen}>
                <FileDownloadIcon />&nbsp;Download Report
            </SoftButton>
            {isOpne && <UpdateBrandModal />}
        </>
    )
}
export default DownloadReport;